<template>
  <div>
    <div class="h3">
      รายงานการ Export
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          รายการ
        </div>
      </div>
      <div class="card-body">
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="search()"
          >
            ค้นหา
          </button>

          <b-modal
            id="exportDepositModal"
            title="ระบุรหัสผ่าน"
            @ok="checkExportPassword"
          >
            <b-form-input v-model="exportPassword" />
          </b-modal>
        </div>
      </div>
      <b-table
        striped
        hover
        small
        responsive
        show-empty
        class="mt-2 position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(created_at)="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
      </b-table>
      <div
        v-if="isLoading"
        class="text-center mt-2"
      >
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
      </div>
      <div class="card-body d-flex justify-content-between flex-wrap">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TransactionListing from './transaction-listing.vue';
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import {
  BTable, BSpinner, BFormGroup, BPagination, BFormSelect, BFormInput,
} from 'bootstrap-vue'
import BankStatus from './bank-status.vue'

export default {
  components: {
    BTable,
    BSpinner,
    BFormGroup,
    BPagination,
    BFormSelect,
    BankStatus,
    // TransactionListing,
    flatPickr,
    BFormInput,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    perPage: 50,
    pageOptions: [50, 100, 200, 'all'],
    totalRows: 50,
    page: 1,
    items: [],
    type: 'all',
    fields: [
      { key: 'username', label: 'ยูส' },
      { key: 'name', label: 'ชื่อ' },
      { key: 'type', label: 'ข้อมูลที่ Export' },
      { key: 'filter', label: 'ฟิลเตอร์' },
      { key: 'created_at', label: 'วันที่' },
    ],
    typeOptions: [
      { value: 'all', text: 'ทั้งหมด' },
      { value: 'dep', text: 'ฝาก' },
      { value: 'wit', text: 'ถอน' },
    ],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(3, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    fromOPs: [
      { value: null, text: 'ทั้งหมด' },
      { value: 'scb', text: 'ธนาคารไทยพาณิชย์' },
      { value: 'kbank', text: 'ธนาคารกสิกรไทย' },
      { value: 'truewallet', text: 'truewallet' },
    ],
    search_from: null,
    exportPassword: '',
  }),
  computed: {
    agent() {
      return this.$store.state.app.agent
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.items = []
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/logs/exports', {
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            type: this.type,
            bank: this.search_from,
          },
        })
        this.totalRows = (this.perPage === 'all') ? data.totalPages : data.total
        this.items = data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    search() {
      this.page = 1
      this.getData()
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    async exportData() {
      try {
        const { data } = await this.$http.get('/export/bank', {
          responseType: 'blob',
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            type: this.type,
            bank: this.search_from,
          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )
        // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
        link.setAttribute('download', 'ReportBank.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
